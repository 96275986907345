import {HashLink} from "react-router-hash-link";
import logo from "../../assets/navbarLogoLight.svg"

const Footer = (props) => {
    return (
        <div
            className={props?.contact ? "bg-[#212121] pt-[270px] pb-[140px] z-[-20]" : "bg-[#212121] pt-[170px] pb-[140px] z-[-20]"}>
            <div className="container mx-auto flex flex-col lg:grid grid-cols-5 ">
                <div className="col-span-2 text-center lg:text-left mx-auto lg:mx-0">
                    <div className="flex items-center">
                        <img src={logo} alt="logo" className="object-cover"/>
                        <h1 className="text-[25px] text-[#FBFBFB] font-semibold ml-[10px]">Habitat Tattoo</h1>
                    </div>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[17px] max-w-[200px] mx-auto lg:mx-0 lg:my-[30px]">Najlepsze
                        studio tatuażu w Twojej okolicy</p>
                    <p className="text-[#fbfbfb80] text-[13px] hidden lg:block">Copyright © 2023 Pracownia Tatuażu Habitat</p>
                    <p className="text-[#fbfbfb80] text-[13px] hidden lg:block">Website made by: weberry.net & envcode.pl </p>
                </div>
                <div className="mx-auto hidden lg:block">
                    <h1 className="text-[25px] text-[#FBFBFB] font-semibold">Linki</h1>
                    <HashLink to="/#o-nas">
                        <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">O nas</p>
                    </HashLink>
                    <HashLink to="/#oferta">
                        <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">Oferta</p>
                    </HashLink>
                    <HashLink to="/#portfolio">
                        <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">Realizacje</p>
                    </HashLink>
                    <HashLink to="/#blog">
                        <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">Blog</p>
                    </HashLink>
                    <HashLink to="/#faq">
                        <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">FAQ</p>
                    </HashLink>
                </div>
                <div className="mx-auto hidden lg:block">
                    <h1 className="text-[25px] text-[#FBFBFB] font-semibold">Socialmedia</h1>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px] cursor-pointer"
                       onClick={() => window.open("https://fb.habitattattoo.pl")}>Facebook</p>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px] cursor-pointer"
                       onClick={() => window.open("https://ig.habitattattoo.pl")}>Instagram</p>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px] cursor-pointer"
                       onClick={() => window.open("https://www.instagram.com/but_first_make_up_ab?igsh=YzVkODRmOTdmMw%3D%3D")}>Instagram Adrianny</p>
                </div>
                <div className="mx-auto hidden lg:block ">
                    <h1 className="text-[25px] text-[#FBFBFB] font-semibold">Skontaktuj się</h1>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">+48 123 321 123</p>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">kontakt@habitattattoo.pl</p>
                </div>
                <div className="mx-auto lg:hidden text-center mt-[70px] cursor-pointer">
                    <h1 className="text-[25px] text-[#FBFBFB] font-semibold">Linki</h1>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">O mnie</p>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">Portfolio</p>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">Blog</p>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">Opinie</p>
                    <p className="text-[16px] text-[#fbfbfbb3] font-medium my-[10px]">FAQ</p>
                </div>
                <div className="mx-auto flex items-center justify-center lg:hidden text-center my-[50px] gap-[50px]">
                    {/*<div className="cursor-pointer" onClick={() => window.open("https://tt.habitattattoo.pl")}>*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none">*/}
                    {/*        <path*/}
                    {/*            d="M20.5332 5.85673C19.285 5.58974 18.1547 4.93104 17.3071 3.97667C16.4596 3.0223 15.939 1.82215 15.8213 0.551178V0H11.5156V17.0878C11.5129 17.8443 11.2733 18.5809 10.8304 19.1942C10.3875 19.8075 9.76352 20.2666 9.04623 20.5069C8.32893 20.7473 7.55435 20.7569 6.83132 20.5344C6.1083 20.3118 5.47317 19.8684 5.0152 19.2662C4.55011 18.654 4.29212 17.9096 4.27862 17.1409C4.26513 16.3722 4.49685 15.6192 4.94016 14.991C5.38348 14.3629 6.01536 13.8923 6.74416 13.6475C7.47296 13.4027 8.2608 13.3964 8.99343 13.6295V9.25504C7.35971 9.02919 5.69625 9.31964 4.23574 10.0858C2.77522 10.8519 1.59069 12.0554 0.847839 13.5278C0.104986 15.0003 -0.159039 16.6682 0.0926943 18.2981C0.344428 19.928 1.09933 21.4385 2.25181 22.6183C3.35086 23.7428 4.76009 24.5144 6.2996 24.8344C7.83911 25.1544 9.43906 25.0085 10.8952 24.4151C12.3514 23.8217 13.5977 22.8079 14.4751 21.503C15.3525 20.1982 15.8212 18.6614 15.8213 17.089V8.35766C17.5614 9.60166 19.6477 10.2687 21.7867 10.2649V5.98672C21.3655 5.98724 20.9453 5.94367 20.5332 5.85673Z"*/}
                    {/*            fill="white"/>*/}
                    {/*    </svg>*/}
                    {/*</div>*/}
                    <div className="cursor-pointer" onClick={() => window.open("https://fb.habitattattoo.pl")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="25" viewBox="0 0 15 25" fill="none">
                            <path
                                d="M13.2974 14.0625L13.9917 9.53809H9.65039V6.60205C9.65039 5.36426 10.2568 4.15771 12.2012 4.15771H14.1748V0.305664C14.1748 0.305664 12.3838 0 10.6714 0C7.09619 0 4.75928 2.16699 4.75928 6.08984V9.53809H0.785156V14.0625H4.75928V25H9.65039V14.0625H13.2974Z"
                                fill="white"/>
                        </svg>
                    </div>
                    <div className="cursor-pointer" onClick={() => window.open("https://ig.habitattattoo.pl")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                            <path
                                d="M12.6814 6.09032C9.13344 6.09032 6.27168 8.95208 6.27168 12.5C6.27168 16.0479 9.13344 18.9097 12.6814 18.9097C16.2293 18.9097 19.091 16.0479 19.091 12.5C19.091 8.95208 16.2293 6.09032 12.6814 6.09032ZM12.6814 16.6671C10.3886 16.6671 8.51423 14.7983 8.51423 12.5C8.51423 10.2017 10.383 8.33287 12.6814 8.33287C14.9797 8.33287 16.8485 10.2017 16.8485 12.5C16.8485 14.7983 14.9741 16.6671 12.6814 16.6671ZM20.8483 5.82813C20.8483 6.65932 20.1789 7.32316 19.3532 7.32316C18.522 7.32316 17.8582 6.65374 17.8582 5.82813C17.8582 5.00251 18.5276 4.33309 19.3532 4.33309C20.1789 4.33309 20.8483 5.00251 20.8483 5.82813ZM25.0935 7.34548C24.9987 5.3428 24.5412 3.56884 23.0741 2.10727C21.6125 0.64571 19.8386 0.188274 17.8359 0.0878612C15.7718 -0.0292871 9.5853 -0.0292871 7.52126 0.0878612C5.52416 0.182696 3.7502 0.640132 2.28306 2.1017C0.815913 3.56326 0.364055 5.33722 0.263642 7.3399C0.146494 9.40394 0.146494 15.5905 0.263642 17.6545C0.358477 19.6572 0.815913 21.4312 2.28306 22.8927C3.7502 24.3543 5.51858 24.8117 7.52126 24.9121C9.5853 25.0293 15.7718 25.0293 17.8359 24.9121C19.8386 24.8173 21.6125 24.3599 23.0741 22.8927C24.5357 21.4312 24.9931 19.6572 25.0935 17.6545C25.2106 15.5905 25.2106 9.40952 25.0935 7.34548ZM22.427 19.8692C21.9919 20.9626 21.1495 21.8049 20.0505 22.2456C18.4049 22.8983 14.4999 22.7477 12.6814 22.7477C10.8628 22.7477 6.95225 22.8927 5.31218 22.2456C4.21879 21.8105 3.37644 20.9681 2.93574 19.8692C2.28306 18.2235 2.43368 14.3186 2.43368 12.5C2.43368 10.6814 2.28863 6.77089 2.93574 5.13082C3.37086 4.03743 4.21321 3.19508 5.31218 2.75438C6.95783 2.1017 10.8628 2.25232 12.6814 2.25232C14.4999 2.25232 18.4105 2.10727 20.0505 2.75438C21.1439 3.1895 21.9863 4.03185 22.427 5.13082C23.0797 6.77647 22.929 10.6814 22.929 12.5C22.929 14.3186 23.0797 18.2291 22.427 19.8692Z"
                                fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className="col-span-2 text-center lg:hidden">
                    <p className="text-[#fbfbfb80] text-[13px]">Copyright © 2023 Habitat Studio</p>
                    <p className="text-[#fbfbfb80] text-[13px]">Website made by: weberry.net & envcode.pl</p>
                </div>
            </div>

        </div>
    )
}

export default Footer;
