import {HashLink} from "react-router-hash-link";
import {useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import logoLight from "../../assets/navbarLogoLight.svg"
import logoDark from "../../assets/navbarLogoDark.svg"

const links = [
    {
        title: "O nas",
        link: "/#o-nas"
    },
    {
        title: "Oferta",
        link: "/#oferta"
    },
    {
        title: "Realizacje",
        link: "/#portfolio"
    },
    {
        title: "Blog",
        link: "/#blog"
    },
    {
        title: "FAQ",
        link: "/#faq"
    },
]

const Navbar = (props) => {

    const [nav, setNav] = useState(false);
    const [scrolling, setScrolling] = useState(false)

    // const handleNav = () => {
    //     setNav(!nav);
    // };

    const handleScroll = () => {
        if (window.scrollY > 60) {
            setScrolling(true);
        } else {
            setScrolling(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])

    return (
        <div
            className={scrolling ? `shadow-2xl navbar fixed z-[500] bg-[#212121] top-0 text-[#fbfbfbb3] w-full h-[100px]` : `fixed navbar z-[500] top-0 text-[${props?.color}] w-full h-[100px]`}>
            <div className="container mx-auto w-full">

                <div className="flex items-center justify-between h-[100px] lg:hidden">
                    <HashLink to="/#">
                        <img src={scrolling ? logoLight : props?.logo} alt="" className="h-[45px]"/>
                    </HashLink>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 28 16" fill="none"
                         onClick={() => setNav(!nav)}>
                        <rect width="28" height="2" rx="1"
                              fill={scrolling ? props?.hamburgerScroll : props?.hamburgerStatic}/>
                        <rect y="7" width="28" height="2" rx="1"
                              fill={scrolling ? props?.hamburgerScroll : props?.hamburgerStatic}/>
                        <rect y="14" width="28" height="2" rx="1"
                              fill={scrolling ? props?.hamburgerScroll : props?.hamburgerStatic}/>
                    </svg>
                </div>

                <div className="flex items-center justify-between h-[100px] hidden lg:flex gap-[50px]">

                    <div className="">
                        <img src={scrolling ? logoLight : props?.logo} alt="Logo" className="h-[45px] mx-[25px]"/>
                    </div>

                    <div className="flex items-center gap-[48px]">
                        <HashLink to="/#o-nas">
                            <p className="text-[16px] font-poppins font-medium leading-[137%]">O nas</p>
                        </HashLink>
                        <HashLink to="/#oferta">
                            <p className="text-[16px] font-poppins font-medium leading-[137%]">Oferta</p>
                        </HashLink>
                        <HashLink to="/#portfolio">
                            <p className="text-[16px] font-poppins font-medium leading-[137%]">Realizacje</p>
                        </HashLink>
                        <HashLink to="/#blog">
                            <p className="text-[16px] font-poppins font-medium leading-[137%]">Blog</p>
                        </HashLink>
                        <HashLink to="/#faq">
                            <p className="text-[16px] font-poppins font-medium leading-[137%]">FAQ</p>
                        </HashLink>
                        <HashLink to="/#kontakt">
                            <p className="text-[16px] font-poppins font-medium leading-[137%]">Skontaktuj się</p>
                        </HashLink>
                    </div>

                    <div className="flex items-center gap-[48px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" viewBox="0 0 14 25" fill="none" className="cursor-pointer" onClick={() => window.location.assign("https://www.facebook.com/habitattattoo")}>
                            <path d="M12.5122 14.0625L13.2065 9.53809H8.86523V6.60205C8.86523 5.36426 9.47168 4.15771 11.416 4.15771H13.3896V0.305664C13.3896 0.305664 11.5986 0 9.88623 0C6.31104 0 3.97412 2.16699 3.97412 6.08984V9.53809H0V14.0625H3.97412V25H8.86523V14.0625H12.5122Z" fill="white"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none" className="cursor-pointer" onClick={() => window.location.assign("https://www.instagram.com/pthabitat/")}>
                            <path d="M12.8952 6.09032C9.34731 6.09032 6.48554 8.95208 6.48554 12.5C6.48554 16.0479 9.34731 18.9097 12.8952 18.9097C16.4431 18.9097 19.3049 16.0479 19.3049 12.5C19.3049 8.95208 16.4431 6.09032 12.8952 6.09032ZM12.8952 16.6671C10.6025 16.6671 8.7281 14.7983 8.7281 12.5C8.7281 10.2017 10.5969 8.33287 12.8952 8.33287C15.1936 8.33287 17.0624 10.2017 17.0624 12.5C17.0624 14.7983 15.188 16.6671 12.8952 16.6671ZM21.0621 5.82813C21.0621 6.65932 20.3927 7.32316 19.5671 7.32316C18.7359 7.32316 18.0721 6.65374 18.0721 5.82813C18.0721 5.00251 18.7415 4.33309 19.5671 4.33309C20.3927 4.33309 21.0621 5.00251 21.0621 5.82813ZM25.3074 7.34548C25.2125 5.3428 24.7551 3.56884 23.288 2.10727C21.8264 0.64571 20.0524 0.188274 18.0498 0.0878612C15.9857 -0.0292871 9.79917 -0.0292871 7.73512 0.0878612C5.73803 0.182696 3.96407 0.640132 2.49692 2.1017C1.02978 3.56326 0.577922 5.33722 0.47751 7.3399C0.360361 9.40394 0.360361 15.5905 0.47751 17.6545C0.572344 19.6572 1.02978 21.4312 2.49692 22.8927C3.96407 24.3543 5.73245 24.8117 7.73512 24.9121C9.79917 25.0293 15.9857 25.0293 18.0498 24.9121C20.0524 24.8173 21.8264 24.3599 23.288 22.8927C24.7495 21.4312 25.207 19.6572 25.3074 17.6545C25.4245 15.5905 25.4245 9.40952 25.3074 7.34548ZM22.6408 19.8692C22.2057 20.9626 21.3634 21.8049 20.2644 22.2456C18.6188 22.8983 14.7138 22.7477 12.8952 22.7477C11.0766 22.7477 7.16612 22.8927 5.52604 22.2456C4.43266 21.8105 3.59031 20.9681 3.14961 19.8692C2.49692 18.2235 2.64754 14.3186 2.64754 12.5C2.64754 10.6814 2.5025 6.77089 3.14961 5.13082C3.58473 4.03743 4.42708 3.19508 5.52604 2.75438C7.1717 2.1017 11.0766 2.25232 12.8952 2.25232C14.7138 2.25232 18.6243 2.10727 20.2644 2.75438C21.3578 3.1895 22.2001 4.03185 22.6408 5.13082C23.2935 6.77647 23.1429 10.6814 23.1429 12.5C23.1429 14.3186 23.2935 18.2291 22.6408 19.8692Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </div>

            {
                nav && (
                    <div className="fixed top-0 left-0 w-full h-full bg-[#FFF] py-[60px] overflow-y-scroll">
                        <div className="container mx-auto h-full w-full">


                            <div className="flex items-center justify-between ">
                                <HashLink to="/#">
                                    <img src={logoDark} alt="Logo" className="h-[45px]"/>
                                </HashLink>
                                <AiOutlineClose size={30} className="text-[#000] cursor-pointer"
                                                onClick={() => setNav(false)}/>
                            </div>

                            <div className="h-full w-full flex items-center justify-center flex-col gap-[50px]">
                                {links.map((link) => (
                                    <HashLink to={link?.link} onClick={() => setNav(false)}>
                                        <p className={`text-[16px] text-[#000] font-poppins font-medium leading-[137%] hover:text-[#C59D5F] hover:font-semibold `}>
                                            {link.title}
                                        </p>
                                    </HashLink>
                                ))}

                                <div className="flex items-center gap-[48px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" viewBox="0 0 14 25" fill="none " className="cursor-pointer" onClick={() => window.location.assign("https://www.facebook.com/habitattattoo")}>
                                        <path d="M12.8149 14.0625L13.5093 9.53809H9.16797V6.60205C9.16797 5.36426 9.77441 4.15771 11.7188 4.15771H13.6924V0.305664C13.6924 0.305664 11.9014 0 10.189 0C6.61377 0 4.27686 2.16699 4.27686 6.08984V9.53809H0.302734V14.0625H4.27686V25H9.16797V14.0625H12.8149Z" fill="black"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none" className="cursor-pointer" onClick={() => window.location.assign("https://www.instagram.com/pthabitat/")}>
                                        <path d="M13.198 6.09032C9.65004 6.09032 6.78828 8.95208 6.78828 12.5C6.78828 16.0479 9.65004 18.9097 13.198 18.9097C16.7459 18.9097 19.6076 16.0479 19.6076 12.5C19.6076 8.95208 16.7459 6.09032 13.198 6.09032ZM13.198 16.6671C10.9052 16.6671 9.03083 14.7983 9.03083 12.5C9.03083 10.2017 10.8996 8.33287 13.198 8.33287C15.4963 8.33287 17.3651 10.2017 17.3651 12.5C17.3651 14.7983 15.4907 16.6671 13.198 16.6671ZM21.3649 5.82813C21.3649 6.65932 20.6955 7.32316 19.8698 7.32316C19.0386 7.32316 18.3748 6.65374 18.3748 5.82813C18.3748 5.00251 19.0442 4.33309 19.8698 4.33309C20.6955 4.33309 21.3649 5.00251 21.3649 5.82813ZM25.6101 7.34548C25.5153 5.3428 25.0578 3.56884 23.5907 2.10727C22.1291 0.64571 20.3552 0.188274 18.3525 0.0878612C16.2884 -0.0292871 10.1019 -0.0292871 8.03786 0.0878612C6.04076 0.182696 4.2668 0.640132 2.79966 2.1017C1.33251 3.56326 0.880657 5.33722 0.780244 7.3399C0.663096 9.40394 0.663096 15.5905 0.780244 17.6545C0.875078 19.6572 1.33251 21.4312 2.79966 22.8927C4.2668 24.3543 6.03518 24.8117 8.03786 24.9121C10.1019 25.0293 16.2884 25.0293 18.3525 24.9121C20.3552 24.8173 22.1291 24.3599 23.5907 22.8927C25.0523 21.4312 25.5097 19.6572 25.6101 17.6545C25.7273 15.5905 25.7273 9.40952 25.6101 7.34548ZM22.9436 19.8692C22.5085 20.9626 21.6661 21.8049 20.5671 22.2456C18.9215 22.8983 15.0165 22.7477 13.198 22.7477C11.3794 22.7477 7.46885 22.8927 5.82878 22.2456C4.73539 21.8105 3.89304 20.9681 3.45234 19.8692C2.79966 18.2235 2.95028 14.3186 2.95028 12.5C2.95028 10.6814 2.80524 6.77089 3.45234 5.13082C3.88746 4.03743 4.72981 3.19508 5.82878 2.75438C7.47443 2.1017 11.3794 2.25232 13.198 2.25232C15.0165 2.25232 18.9271 2.10727 20.5671 2.75438C21.6605 3.1895 22.5029 4.03185 22.9436 5.13082C23.5963 6.77647 23.4456 10.6814 23.4456 12.5C23.4456 14.3186 23.5963 18.2291 22.9436 19.8692Z" fill="black"/>
                                    </svg>
                                </div>

                                <HashLink to="/#kontakt">
                                    <button
                                        className="py-[20px] px-[24px] bg-[#C59D5F] rounded-[3px] text-[13px] text-[#FFF] font-medium">
                                        Skontaktuj się
                                    </button>
                                </HashLink>
                            </div>

                        </div>
                    </div>
                )
            }

        </div>

        // <div className={scrolling ? `shadow-2xl navbar w-full fixed z-[500] bg-[#212121] top-0 text-[#fbfbfbb3]` : `fixed navbar w-full z-[500] top-0 text-[${props?.color}]`}>
        //     <div className='container mx-auto py-[20px] flex justify-between items-center w-full'>
        //         <div className="w-full flex items-center justify-between lg:justify-center">
        //             <div className="block lg:hidden">
        //                 <HashLink>
        //                     <img src={scrolling ? props?.logo : logoLight} alt="Logo" className="h-[52px] w-[39px]"/>
        //                 </HashLink>
        //             </div>
        //             <ul className={`hidden lg:flex lg:items-center text-[13px] `}>
        //                 <HashLink to="/#o-nas">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">O nas</li>
        //                 </HashLink>
        //                 <HashLink to="/#oferta" >
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Oferta</li>
        //                 </HashLink>
        //                 <HashLink to="/#portfolio">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Realizacje</li>
        //                 </HashLink>
        //                 <HashLink to="/#">
        //                     <img src={scrolling ? logoLight : props?.logo} alt="Logo" className="h-[52px] w-[39px] mx-[25px]"/>
        //                 </HashLink>
        //                 <HashLink to="/#blog">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Blog</li>
        //                 </HashLink>
        //                 <HashLink to="/#faq">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">FAQ</li>
        //                 </HashLink>
        //                 <HashLink to="/#kontakt">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Skontaktuj się</li>
        //                 </HashLink>
        //             </ul>
        //
        //             <div onClick={handleNav} className='block lg:hidden'>
        //                 <AiOutlineMenu size={30} className={`text-[${props?.color}] cursor-pointer`}/>
        //             </div>
        //
        //         </div>
        //         <ul className={nav ? 'fixed left-0 top-0 w-full h-full bg-[#FFF] ease-in-out duration-500 z-[500] mx-auto py-[20px]' : 'ease-in-out duration-500 fixed left-[-100%]'} onClick={() => setNav(false)}>
        //             <div className="container flex items-center justify-between mx-auto">
        //                 <HashLink to="/#">
        //                     <img src={logoDark} alt="Logo" className="h-[52px] w-[39px]"/>
        //                 </HashLink>
        //                 <AiOutlineClose size={30} className="text-[#000] cursor-pointer" onClick={() => setNav(false)}/>
        //             </div>
        //             <div className="text-center py-[30px]">
        //                 {links.map((link) => (
        //                     <HashLink to={link?.link}>
        //                         <li className={`mx-[16px] text-[16px] text-[#000] font-poppins font-medium leading-[137%] hover:text-[#1364EE] hover:font-semibold py-[20px]`}>
        //                             {link.title}
        //                         </li>
        //                     </HashLink>
        //                 ))}
        //             </div>
        //             <div className="flex flex-col items-center">
        //                 <HashLink to="/#kontakt">
        //                     <button className="py-[20px] px-[24px] bg-[#C59D5F] rounded-[3px] text-[13px] text-[#FFF] font-medium">
        //                         Skontaktuj się
        //                     </button>
        //                 </HashLink>
        //             </div>
        //         </ul>
        //     </div>
        // </div>

    )
}

export default Navbar;